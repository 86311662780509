/* eslint-disable max-len */
import { signal } from '@preact/signals-react'
import { v4 as uuidV4 } from 'uuid'
import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { Group, Rule } from 'types'
import { catchable } from 'utils'
import { ToastLevel, ToastSignal } from 'components'

export const RuleSignal = signal<Rule[]>([])
export const GroupSignal = signal<Group[]>([])

const transformArrayToObject = (arr: unknown[]) => {
  const result = {}

  arr?.filter(Boolean).forEach(item => {
    Object.assign(result, item)
  })

  return result
}

export const getRules = async () => {
  const [error, response] = await catchable(() => get<Rule[]>('/miki/rules'), true)
  if (error) return

  // transform accepted-values default-value values to array of objects
  RuleSignal.value = response.map(rule => {
    if (rule.kind === 'accepted-values' || rule.kind === 'default-value') {
      return { ...rule, value: Object.entries(rule.value).map(([key, value]) => ({ [key]: value })), id: uuidV4() }
    }
    return { ...rule, id: uuidV4() }
  })
}

export const postRule = async () => {
  // transform accepted-values default-value values to object
  const [error] = await catchable(() => post<Rule[]>('/miki/rules', RuleSignal.value.map(rule => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = rule

    if (rule.kind === 'accepted-values' || rule.kind === 'default-value') {
      const value = transformArrayToObject(rule.value)
      return value ? { ...rest, value } : undefined
    }

    if (rule.kind === 'mandatory-columns') {
      const value = rule.value?.filter(Boolean)
      return value ? { ...rest, value } : undefined
    }

    return rest
  }).filter(Boolean)), true)

  if (!error) {
    ToastSignal.value = { message: 'Nouvelles règles enregistrées', severity: ToastLevel.SUCCESS }
  }
}

export const getGroups = async () => {
  const [error, response] = await catchable(() => get<Group[]>('/miki/groups', {
    display_protected: 'True',
  }), true)
  if (!error) GroupSignal.value = response
}

export const postGroup = async (name: string) => {
  const [error, response] = await catchable(() => post<Group>('/miki/groups', { name }), true)
  if (error) return
  ToastSignal.value = { message: 'Nouveau groupe créé', severity: ToastLevel.SUCCESS }
  GroupSignal.value = [...GroupSignal.value, response]
}

export const deleteGroup = async (id: string) => {
  const [error] = await catchable(() => deleteRequest(`/miki/groups/${id}`), true)
  if (error) return
  ToastSignal.value = { message: 'Groupe supprimé', severity: ToastLevel.SUCCESS }
  GroupSignal.value = GroupSignal.value.filter(group => group.id !== id)
  RuleSignal.value = RuleSignal.value.filter(rule => rule.group !== id)
}
