import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import terms from 'assets/terms'
import { TextInput } from 'components/inputs'
import { useState } from 'react'
import { Button, ButtonSize, ButtonStyle } from 'components'
import { postGroup } from 'services'
import { ModalSignal } from '../ModalWrapper'

export default function CreateGroupModal() {
  const [name, setName] = useState('')

  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleSetName = (value: string) => {
    setName(value)
  }

  const handleCreateGroup = () => {
    // Create group
    postGroup(name)
    handleClose()
  }

  return (
    <>
      <h3>
        {terms.Modals.CreateGroup.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p className="modal-info">
        {terms.Modals.CreateGroup.info}
      </p>
      <TextInput
        label="Nom du groupe"
        bindedValue={name}
        onChange={handleSetName}
      />
      <div className="actions flex-center">
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleClose}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.create}
          onClick={handleCreateGroup}
        />
      </div>
    </>
  )
}
