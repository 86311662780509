import { useNavigate } from 'react-router-dom'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import terms from 'assets/terms'
import { useMountEffect } from 'utils'
import { getRequests, RequestSignal, useRoles } from 'services'
import { Button, ModalSignal, ToolsWrapper, RequestModal } from 'components'
import Requests from './Requests'

import './HomePage.scss'

export default function HomePage() {
  useMountEffect(getRequests)
  const { isAdmin } = useRoles()
  const navigate = useNavigate()
  const requests = RequestSignal.value
  const handleNewRequest = () => { ModalSignal.value = <RequestModal /> }
  const handleToggleRole = () => { navigate('/admin') }

  return (
    <div id="home" className="page with-toolbar">
      <ToolsWrapper>
        <Button
          text={terms.Pages.Home.newRequest}
          icon={<PlusIcon />}
          onClick={handleNewRequest}
        />
        {isAdmin && (
          <Button
            text={terms.Pages.Home.toggle}
            onClick={handleToggleRole}
          />
        )}
      </ToolsWrapper>
      {!requests.length && (
        <p className="no-requests" title="salut">
          {terms.Pages.Home.noRequests}
        </p>
      )}
      {requests.length && (
        <Requests />
      )}
    </div>
  )
}
